import React, { useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';

import Icon from '../Icon';
import Tooltip from '../Tooltip';
import InputRange from '../InputRange';

import './CinePlayer.css';
import classNames from 'classnames';
import { useShareLinkVMContext } from '@/components/provider/ShareLinkProvider';
import { utils } from '@ohif/core';
import { cookieKey } from '@/config/constants';

const { cookie } = utils;

export type CinePlayerProps = {
  className: string;
  isPlaying: boolean;
  minFrameRate?: number;
  maxFrameRate?: number;
  stepFrameRate?: number;
  frameRate?: number;
  onFrameRateChange: (value: number) => void;
  onPlayPauseChange: (value: boolean) => void;
  onClose: () => void;
};

const fpsButtonClassNames =
  'cursor-pointer hover:bg-green-19dbc4 hover:text-white w-4 flex items-center justify-center';

const CinePlayer: React.FC<CinePlayerProps> = ({
  className,
  isPlaying,
  minFrameRate,
  maxFrameRate,
  stepFrameRate,
  frameRate: defaultFrameRate,
  onFrameRateChange,
  onPlayPauseChange,
  onClose,
}) => {
  console.log('defaultFrameRate:', defaultFrameRate);
  const [frameRate, setFrameRate] = useState(defaultFrameRate);
  const debouncedSetFrameRate = debounce(frameRate => {
    cookie.set(cookieKey.CINE_FPS, { data: frameRate });
    onFrameRateChange(frameRate);
  }, 300);
  const { shareLinkVM } = useShareLinkVMContext();

  const getPlayPauseIconName = () => (isPlaying ? 'icon-pause' : 'icon-play');

  const handleSetFrameRate = (frameRate: number) => {
    if (frameRate < minFrameRate || frameRate > maxFrameRate) {
      return;
    }
    setFrameRate(frameRate);
    debouncedSetFrameRate(frameRate);
  };

  return (
    <div
      className={classNames(
        className,
        'border-green-19dbc4 bg-gray-2b2b2b flex select-none items-center gap-2 rounded border px-2 py-2'
      )}
    >
      <Icon
        name={getPlayPauseIconName()}
        className="bg-green-19dbc4 cursor-pointer text-white rounded"
        onClick={() => onPlayPauseChange(!isPlaying)}
      />
      <Tooltip
        position="top"
        className="group/fps cine-fps-range-tooltip"
        tight={true}
        content={
          <InputRange
            containerClassName="h-9 px-2"
            inputClassName="w-40"
            value={frameRate}
            minValue={minFrameRate}
            maxValue={maxFrameRate}
            step={stepFrameRate}
            onChange={handleSetFrameRate}
            showLabel={false}
          />
        }
      >
        <div className="border-green-19dbc4 flex h-6 items-stretch gap-1 rounded border text-green-19dbc4">
          <div
            className={`${fpsButtonClassNames} rounded-l`}
            onClick={() => handleSetFrameRate(frameRate - 1)}
          >
            <Icon name="arrow-left-small" />
          </div>
          <div className="w-11 text-center text-sm leading-[22px]">{`${frameRate} FPS`}</div>
          <div
            className={`${fpsButtonClassNames} rounded-r`}
            onClick={() => handleSetFrameRate(frameRate + 1)}
          >
            <Icon name="arrow-right-small" />
          </div>
        </div>
      </Tooltip>

      {!shareLinkVM.isOpenByShareLink && (
        <Icon
          name="icon-close"
          className="text-green-19dbc4 hover:bg-green-19dbc4 hover:text-white cursor-pointer hover:rounded"
          onClick={onClose}
        />
      )}
    </div>
  );
};

const noop = () => {};

CinePlayer.defaultProps = {
  isPlaying: false,
  minFrameRate: 1,
  maxFrameRate: 90,
  stepFrameRate: 1,
  frameRate: cookie.get(cookieKey.CINE_FPS).data ?? 12,
  onPlayPauseChange: noop,
  onFrameRateChange: noop,
  onClose: noop,
};

CinePlayer.propTypes = {
  /** Minimum value for range slider */
  minFrameRate: PropTypes.number,
  /** Maximum value for range slider */
  maxFrameRate: PropTypes.number,
  /** Increment range slider can "step" in either direction */
  stepFrameRate: PropTypes.number,
  frameRate: PropTypes.number,
  /** 'true' if playing, 'false' if paused */
  isPlaying: PropTypes.bool.isRequired,
  onPlayPauseChange: PropTypes.func,
  onFrameRateChange: PropTypes.func,
  onClose: PropTypes.func,
};

export default CinePlayer;
