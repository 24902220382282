import React from 'react';
import classNames from 'classnames';
import { Svg } from '@ohif/ui';
import { DeviceItem } from '@/viewModel/setting/DeviceItem';
import { OnClickOutside } from '@/components/OnClickOutside/OnClickOutside';
import { useOpenState } from '@/hooks';
import { HubStatusVM } from '@/viewModel/setting/HubStatusVM';

type Props = {
  className?: string;
  children?: React.ReactNode;
  deviceItem: DeviceItem;
  hubStatusVM: HubStatusVM;
};

export const DeviceCard = ({ className, deviceItem, hubStatusVM }: Props) => {
  const status = deviceItem.isConnected ? 'connected' : 'disconnected';
  const openState = useOpenState();

  return (
    <div
      className={classNames(
        'relative border rounded-[8px] p-[36px] max-xl:px-[24px]',
        status === 'connected' && 'border-gray-c9c9c9',
        status === 'disconnected' && 'border-red-eb1e4b',
        className
      )}
    >
      <OnClickOutside handler={openState.close}>
        <div
          className="absolute z-[1] right-[10px] top-[10px] size-[45px] cursor-pointer flex-center"
          onClick={openState.open}
        >
          {!openState.isOpen && (
            <Svg
              name="icMore"
              className="block w-[24px] h-[24px]"
            />
          )}

          {openState.isOpen && (
            <div className="rounded-[8px] border border-gray-666 bg-gray-202020 text-white">
              <div
                className="flex-center px-[16px] py-[12px]"
                onClick={() => hubStatusVM.downloadLogUrl(deviceItem.data.pacs_remote_id)}
              >
                <p>Download log</p>
              </div>
            </div>
          )}
        </div>
      </OnClickOutside>

      <div
        className={classNames(
          'flex-center flex-col',
          status === 'connected' && 'text-green-19dbc4',
          status === 'disconnected' && 'text-red-eb1e4b'
        )}
      >
        <Svg
          name="icTerminal"
          className="block w-[24px] h-[24px]"
        />
        <p className={classNames('text-[14px] font-bold mb-[24px]')}>
          {deviceItem.isConnected ? 'Connected' : 'Disconnected'}
        </p>
      </div>

      <div className="mb-[16px] break-all">
        <p className="text-gray-c9c9c9">Device ID</p>
        <div className="w-full flex i-center bg-gray-2b2b2b min-h-[44px] rounded-[8px] px-[10px]">
          {deviceItem.data.hub_device_id}
        </div>
      </div>

      <div className="mb-[16px] break-all">
        <p className="text-gray-c9c9c9">Last connection time</p>
        <div className="w-full flex i-center bg-gray-2b2b2b min-h-[44px] rounded-[8px] px-[10px]">
          {deviceItem.aliveTime}
        </div>
      </div>

      <div className="mb-[16px]">
        <p className="text-gray-c9c9c9">Last upload time</p>
        <div className="w-full flex i-center bg-gray-2b2b2b min-h-[44px] rounded-[8px] px-[10px]">
          {deviceItem.uploadTime}
        </div>
      </div>

      <div className="mb-[16px]">
        <p className="text-gray-c9c9c9">Local IP</p>
        <div className="w-full flex i-center bg-gray-2b2b2b min-h-[44px] rounded-[8px] px-[10px]">
          {deviceItem.data.hub_local_ip || '--'}
        </div>
      </div>
    </div>
  );
};
