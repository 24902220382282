import { apiGetUnitInfo } from '@/api/unit';
import { smarthubApi } from '@/api/smarthub';
import { doApi, ApiLoading } from '@/viewModel/helper/doApi';
import { useState } from 'react';
import { DeviceItem } from '@/viewModel/setting/DeviceItem';
import { downloadHelper } from '@/utils';
import { useDialog, DialogService } from '@/components/Dialog/SmartPacsDialogProvider';
import dayjs from 'dayjs';

export class HubStatusVM {
  constructor() {
    const { dialog } = useDialog();
    this.dialog = dialog;
    [this.deviceList, this.setDeviceList] = useState([]);
  }

  actionLoading = new ApiLoading();
  dataLoading = new ApiLoading();
  dialog: DialogService;
  interval;

  deviceList: DeviceItem[];
  setDeviceList: React.Dispatch<React.SetStateAction<DeviceItem[]>>;

  async downloadLogUrl(pacsRemoteId: string) {
    this.dialog.open({
      icon: 'alert',
      content: 'downloading ...',
      positiveText: '',
      negativeText: '',
      isLoading: true,
    });

    let status: 'success' | 'noLogUrl' | 'none' = 'none';

    const fn = async () => {
      const res = await smarthubApi.getBindingInfo(pacsRemoteId);
      const logUrl = res.data.data.log_url;

      if (!logUrl) {
        status = 'noLogUrl';
        return;
      }

      const fileName = `hub_log_${pacsRemoteId}_${dayjs().format('YYYYMMDD')}`;
      await downloadHelper.downloadFileByUrl(logUrl, fileName);
      status = 'success';
    };

    const successCallback = () => {
      this.dialog.update({
        content: 'success',
        positiveText: 'Ok',
        negativeText: '',
        isLoading: false,
      });
    };

    const errorCallback = () => {
      this.dialog.update({
        content: 'download failure',
        positiveText: 'Ok',
        negativeText: '',
        isLoading: false,
      });
    };

    const noLogUrlCallback = () => {
      this.dialog.update({
        content: 'No log files to download',
        positiveText: 'Ok',
        negativeText: '',
        isLoading: false,
      });
    };

    await doApi(fn, this.actionLoading, 'download log url error', { errorCallback });

    if (status === 'success') {
      successCallback();
    }
    if (status === 'noLogUrl') {
      noLogUrlCallback();
    }
  }

  async getDeviceList() {
    const fn = async () => {
      const res = await apiGetUnitInfo();
      const deviceList = res.data.data.bindings.map(item => {
        return new DeviceItem(item);
      });
      this.setDeviceList(deviceList);
    };

    await doApi(fn, this.dataLoading, 'get device list error');
  }

  intervalRefreshDeviceList() {
    this.getDeviceList();

    this.interval = setInterval(() => {
      this.getDeviceList();
    }, 60000); // 1分鐘
  }

  destroyIntervalRefresh() {
    clearInterval(this.interval);
  }
}
