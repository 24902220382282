import React, { useEffect } from 'react';
import { PacsButton } from '@/components/common/PacsButton';
import { DisabledButton } from '@/components/common/DisabledButton';
import { TextInput } from '@mantine/core';
import { ShareStudyVM } from '@/viewModel/ShareStudy/ShareStudyVM';
import { ProgressLoadingBar } from '@ohif/ui';
import classNames from 'classnames';
import { Svg } from '@ohif/ui';

type Props = {
  onClose: () => Promise<void> | void;
  shareStudyVM: ShareStudyVM;
};

export const ViewAndDownload = ({ onClose, shareStudyVM }: Props) => {
  const { shareDownloadVM } = shareStudyVM;

  useEffect(() => {
    shareStudyVM.enableEmailForm();
  }, []);

  return (
    <div>
      <div className="flex mb-[22px] px-[36px]">
        <Svg
          name="icDownload"
          className="block w-[32px] h-[42px] mr-[8px] text-green-19dbc4"
        />
        <div className="text-[16px]">
          <h3 className="font-bold">
            {shareStudyVM.isDownloadMultpleDicom ? 'Download DICOM' : 'View and Download'}
          </h3>
          {Boolean(shareDownloadVM.expiredDate) && (
            <p>This link will expired in 7 days, {shareDownloadVM.expiredDate}</p>
          )}
        </div>
      </div>

      <div
        className={classNames(
          'w-full h-full',
          shareStudyVM.isDownloadMultpleDicom && 'flex flex-col i-center',
          !shareStudyVM.isDownloadMultpleDicom &&
            'grid grid-cols-[1fr_2px_1fr] max-640:grid-cols-1 gap-x-[36px] px-[36px] mb-[16px]'
        )}
      >
        {/*
          left
        */}
        {!shareStudyVM.isDownloadMultpleDicom && (
          <>
            <div className="">
              {shareStudyVM.shareLoading.isLoading && (
                <div className="flex j-center mb-[36px] w-full max-w-[192px] mx-auto">
                  <ProgressLoadingBar />
                </div>
              )}

              {!shareStudyVM.shareLoading.isLoading && (
                <div className="flex j-center mb-[24px]">
                  <img
                    src={shareStudyVM.qrCodeSrc}
                    className="block w-[215px] h-[215px]"
                    alt="share link qr code"
                  />
                </div>
              )}

              <div className="flex j-center">
                <p
                  className="text-green-19dbc4 cursor-pointer underline text-center hover:text-opacity-80 font-bold mb-[8px] select-none"
                  onClick={() => shareStudyVM.copyShareLink()}
                >
                  Copy Link
                </p>
              </div>
            </div>
            <div className="w-0 h-full border-r border-r-[#3E5D59] max-640:hidden"></div>
          </>
        )}

        {/*
          right
        */}
        <div className="w-full h-full text-gray-f9f9f9 max-640:mt-[40px]">
          {shareStudyVM.isShareError && (
            <div>
              <p>
                This DICOM is not allowed to be downloaded. Please contact the service provider.
              </p>
            </div>
          )}

          <div className="flex flex-col i-center ">
            {shareDownloadVM.actionType === 'downloadLink' && (
              <>
                <div className="flex j-center mb-[24px]">
                  <img
                    className="block w-[215px] h-[215px]"
                    src={shareDownloadVM.dicomDownloadQrCodeSrc}
                    alt="qrcode"
                  />
                </div>
                <p
                  className="text-green-19dbc4 cursor-pointer underline text-center hover:text-opacity-80 font-bold mb-[20px] select-none"
                  onClick={() => shareDownloadVM.copyDownloadLink()}
                >
                  Copy Link
                </p>
                <p
                  className="text-green-19dbc4 cursor-pointer underline text-center hover:text-opacity-80 font-bold mb-[8px] select-none"
                  onClick={() => shareDownloadVM.downloadDicom()}
                >
                  Download DICOM
                </p>
              </>
            )}
          </div>
        </div>
      </div>

      {shareDownloadVM.actionType === 'downloadLink' && (
        <div
          className={classNames(
            'noPrint px-[80px]',
            shareStudyVM.isDownloadMultpleDicom && 'mt-[16px]'
          )}
        >
          <div className="relative flex i-center mb-[16px]">
            <div className="flex-shrink-0 mr-[12px]">
              <p>Email links to</p>
            </div>

            <div className="w-full">
              <TextInput
                placeholder="Enter email"
                value={shareStudyVM.formData.email}
                onChange={event =>
                  shareStudyVM.updateFormField('email', event.currentTarget.value, true)
                }
              />
            </div>
          </div>

          <div className="w-full grid grid-cols-2 gap-x-[8px]">
            <PacsButton
              theme="secondary"
              className="block w-full h-[40px] "
              onClick={() => window.print()}
            >
              <span className="text-[14px] font-bold">Print</span>
            </PacsButton>

            {!shareStudyVM.formData.email || shareStudyVM.formData.errorMsg ? (
              <DisabledButton>Send</DisabledButton>
            ) : (
              <PacsButton
                className={classNames(
                  'block w-full h-[40px]',
                  shareStudyVM.actionLoading.isLoading && 'pointer-events-none'
                )}
                isLoading={shareStudyVM.actionLoading.isLoading}
                onClick={() => shareStudyVM.emailDicom()}
              >
                <span className="text-green-182c2a text-[14px] font-bold">Send</span>
              </PacsButton>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
