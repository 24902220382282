import { fetchRequest } from '@/api/helper';
import { BaseApi } from '@/api/base/BaseApi';

class SmarthubApi extends BaseApi {
  getBindingInfo(pacsRemoteId: string) {
    return fetchRequest<{ data: any }>(`/binding/${pacsRemoteId}`, {
      method: 'get',
      headers: this.getAuthHeader(),
    });
  }
}

export const smarthubApi = new SmarthubApi();
