import React from 'react';
// Svgs
import icLogo from './../../assets/svgs/ic_logo.svg';
import icSmart from './../../assets/svgs/ic_smart.svg';
import icPacs from './../../assets/svgs/ic_pacs.svg';
import icArrow from './../../assets/svgs/ic_arrow.svg';
import icSearch from './../../assets/svgs/ic_search.svg';
import icTriangle from './../../assets/svgs/ic_triangle.svg';
import icSettingFill from './../../assets/svgs/ic_setting_fill.svg';
import icSettingOutline from './../../assets/svgs/ic_settings_outline.svg';
import icAdd from './../../assets/svgs/ic_add.svg';
import icShare from './../../assets/svgs/ic_share_outline.svg';
import icCheck from './../../assets/svgs/ic_check.svg';
import icAlert from './../../assets/svgs/ic_alert.svg';
import icMpr from './../../assets/svgs/ic_mpr.svg';
import icDog1 from './../../assets/svgs/ic_dog_1.svg';
import icDog2 from './../../assets/svgs/ic_dog_2.svg';
import icList from './../../assets/svgs/ic_list.svg';
import icClose from './../../assets/icons/icon-close.svg';
import icDelete from './../../assets/svgs/ic_delete.svg';
import icFileDeleted from './../../assets/svgs/ic_file_deleted.svg';
import icTerminal from './../../assets/svgs/ic_terminal.svg';
import icSelect from './../../assets/svgs/ic_select.svg';
import icInstance from './../../assets/svgs/ic_instances.svg';
import icUpload from './../../assets/svgs/ic_upload.svg';
import icFrames from './../../assets/svgs/ic_frames.svg';
import icViewOnly from './../../assets/svgs/ic_view_only.svg';
import icDownload from './../../assets/svgs/ic_download.svg';
import icStudies from './../../assets/svgs/ic_studies.svg';
import icMore from './../../assets/svgs/ic_more.svg';
import { smartPacsToolIconMap } from './smartPacsToolIconMap';

const SVGS = {
  'logo-ohif': icLogo,
  icLogo,
  icSmart,
  icPacs,
  icArrow,
  icSearch,
  icTriangle,
  icSettingFill,
  icSettingOutline,
  icAdd,
  icShare,
  icCheck,
  icAlert,
  icMpr,
  icDog1,
  icDog2,
  icList,
  icClose,
  icDelete,
  icFileDeleted,
  icTerminal,
  icSelect,
  icInstance,
  icUpload,
  icFrames,
  icViewOnly,
  icDownload,
  icStudies,
  icMore,
  ...smartPacsToolIconMap,
};

/**
 * Return the matching SVG as a React Component.
 * Results in an inlined SVG Element. If there's no match,
 * return `null`
 */
export default function getSvg(key, props) {
  if (!key || !SVGS[key]) {
    return React.createElement('div', null, 'Missing SVG');
  }

  return React.createElement(SVGS[key], props);
}

export { SVGS };
