import React from 'react';
import classNames from 'classnames';
import { useRadioOptionList } from '@/components/RadioOptionList/useRadioOptionList';
import { RadioOptionItem } from '@/components/RadioOptionList/RadioOptionList';
import { RadioOptionList } from '@/components/RadioOptionList/RadioOptionList';

type Props = {
  className?: string;
  children?: React.ReactNode;
  defaultDayOption?: DayOption;
  onClickDayOption: (option: RadioOptionItem<DayOption>) => Promise<void> | void;
};

export const StudyDayRadioOptionList = ({
  className,
  defaultDayOption = 'last7Days',
  onClickDayOption,
}: Props) => {
  const radioOptionState = useRadioOptionList<DayOption>([
    { label: 'Last 7 Days', key: 'last7Days', isCheck: defaultDayOption === 'last7Days' },
    { label: 'Last 30 Days', key: 'last30Days', isCheck: defaultDayOption === 'last30Days' },
    { label: 'Today', key: 'today', isCheck: defaultDayOption === 'today' },
  ]);

  return (
    <RadioOptionList
      optionList={radioOptionState.tabList}
      onClickOption={arrIndex => {
        const item = radioOptionState.handleClickTab(arrIndex);
        onClickDayOption(item);
      }}
      className={classNames('', className)}
    />
  );
};
