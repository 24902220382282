import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useOnClickOutside } from '@/hooks';

interface Props extends React.ComponentProps<'div'> {
  className?: string;
  children?: React.ReactNode;
  handler: () => Promise<void> | void;
}

export const OnClickOutside = ({ className, children, handler }: Props) => {
  const domRef = useRef<HTMLElement>(null);
  const clickOutsideListener = useOnClickOutside(domRef, handler);

  useEffect(() => {
    clickOutsideListener.add();

    return () => {
      clickOutsideListener.remove();
    };
  }, []);

  return (
    <div
      ref={domRef}
      className={classNames(className)}
    >
      {children}
    </div>
  );
};
